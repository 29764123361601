import {
  Box,
  Container,
  Dialog,
  Grid,
  IconButton,
} from "@mui/material";
import { EnrollFormBox } from "./EnrollDialogForm.styled";
import { CrossIcon, WhatsAppIcon } from "ui/icons";
import { useAppContext } from "providers/DeviceProvider";
import Link from "next/link";
export interface SimpleDialogProps {
  onOpen: boolean;
  onClose: () => void;
}

const EnrollDialogForm = (props: SimpleDialogProps) => {
  const { isTablet } = useAppContext();
  const { onClose, onOpen } = props;

  return (
    <Dialog
      onClose={() => {
        onClose()
      }}
      open={onOpen}
      maxWidth="lg"
      className={!isTablet ? "" : "tablet"}
      aria-describedby="alert-dialog-slide-description"
    >
      <EnrollFormBox>
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose()
          }}
          className="enroll-form-close"
        >
          <CrossIcon className="close-icon" />
        </IconButton>
        <Container maxWidth="xl">
          <Grid container columnSpacing={3}>
            {/* <Grid item xs={12} style={{ justifyContent: 'start', display: 'flex' }}>
              <Link
                prefetch={false}
                passHref
                className="contact-links"
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=61404797620&text=Hey, I contacted you through the PY website.`}
              >
                <WhatsAppIcon className="contact-txt-icon" />
              </Link>
            </Grid> */}
            <Grid item xs={12}>
              <Box className="form-iframe-box">
                <iframe
                  className="form-iframe"
                  src="https://www.jotform.com/form/231560689649066"
                ></iframe>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </EnrollFormBox>
    </Dialog>
  );
};

export default EnrollDialogForm;
