import { Box, styled } from "@mui/material";
// import { before } from "node:test";

export const EnrollFormBox = styled(Box)(() => ({
  position: "relative",
  paddingTop: "30px",
  paddingBottom: "30px",
  ".enroll-form-close": {
    position: "absolute",
    right: "5px",
    top: "5px",
    ".close-icon": {
      fontSize: "24px",
    },
  },
  ".enroll-form-title": {
    marginTop: "10px",
    marginBottom: "15px",
    paddingBottom: "30px",
    fontSize: "30px",
    lineHeight: "30px",
  },
  ".enroll-box-btn": {
    position: "relative",
    marginTop: "20px",
    textAlign: "center",
    ".enroll-btn": {
      minWidth: "220px",
    },
  },
  ".enroll-box-img": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    ".enroll-img": {
      width: "76%",
    },
  },
  ".enroll-error": {
    color: "red",
  },
  ".group-radio-custom": {
    position: "relative",
    marginTop: "15px",
    ".group-label": {
      fontSize: "16px",
      color: "#000000",
      marginBottom: "5px",
    },
  },
  ".group-radio-custom-box": {
    position: "relative",
    display: "flex",
    flexWrap: "inherit",
    flexDirection: "initial",
    alignItems: "center",
    ".cust-radio-group": {},
  },
  ".cust-radio-btn": {
    width: "50px",
    "&.Mui-checked": {
      svg: {
        width: "29px",
        height: "29px",
      },
    },
    svg: {
      width: "20px",
      height: "20px",
    },
  },
  ".form-iframe-box": {
    position: "relative",
    width: "100%",
    height: "100vh",
    ".form-iframe": {
      width: "100%",
      height: "100%",
      border: "0",
      paddingBottom: '100px',
    },
  },
}));
